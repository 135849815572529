import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import Lottie from "lottie-react";
import successAnimation from "../../assets/gif/success.json"; // Replace with your Lottie animation file
import CloseIcon from "@mui/icons-material/Close";

const SuccessModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Centering the modal
    >
      <Box
        sx={{
          position: "relative",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          outline: "none",
          opacity: 0.95,
          textAlign: "center",
          backgroundImage: "linear-gradient(135deg, #FFC300 30%, #FF5733 70%)",
          animation: "fadeIn 0.5s ease-out", // Fade-in effect
          "@keyframes fadeIn": {
            from: { opacity: 0 },
            to: { opacity: 1 }
          }
        }}
      >
         <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "transparent",
              transform: "scale(1.1)", // Slightly enlarge the button on hover
              transition: "transform 0.2s ease-in-out",
            },
            animation: "bounceIn 0.5s ease-out",
            "@keyframes bounceIn": {
              from: { opacity: 0, transform: "scale(0.5)" },
              to: { opacity: 1, transform: "scale(1)" }
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Lottie animationData={successAnimation} loop={false} style={{ width: 150, height: 150 }} />
        </Box>
        <Typography
          id="success-modal-title"
          variant="h4"
          component="h2"
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "#001D3D",
            mb: 1,
            opacity: 0,
            animation: "fadeInText 0.5s ease-out forwards", // Text animation
            "@keyframes fadeInText": {
              from: { opacity: 0, transform: "translateY(-10px)" },
              to: { opacity: 1, transform: "translateY(0)" }
            }
          }}
        >
          Thank You!
        </Typography>
        <Typography
          id="success-modal-description"
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.25rem",
            color: "#FFFFFF",
            mb: 3,
            opacity: 0,
            animation: "fadeInText 0.5s ease-out 0.3s forwards", // Text animation with delay
            "@keyframes fadeInText": {
              from: { opacity: 0, transform: "translateY(-10px)" },
              to: { opacity: 1, transform: "translateY(0)" }
            }
          }}
        >
          Your submission has been received. We will contact you soon.
        </Typography>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
