import idea from "../assets/idea.png";
import achievement from "../assets/achievement.png";
import repair from "../assets/repair.png";
import affordableExcellence from "../assets/affordableExcellence.png";
import privateGuard from "../assets/privateGuard.png";
import onSite from "../assets/onSite.png";

export const services = [
  {
    title: "Affordable Excellence",
    description:
      "Secure your world with top-notch, budget-friendly solutions from ManKart Securities. Trust us for reliable protection",
    image: affordableExcellence,
  },
  {
    title: "Private Guard",
    description:
      "Offering elite, affordable security solutions tailored to your needs. Trust us to protect what matters most",
    image: privateGuard,
  },
  {
    title: "On-site Security",
    description:
      "Providing reliable, cost-effective security services for your premises. Trust us to safeguard your property around the clock",
    image: onSite,
  },
];
export const cardsData = [
  {
    image: achievement,
    title: "Certified elite officers",
    description:
      " Delivering top-tier, affordable security with highly trained and certified professionals. Trust us for unmatched protection and peace of mind",
  },
  {
    image: idea,
    title: "Physically fit and well-dressed",
    description:
      "Our elite officers are not only highly trained but also maintain peak physical fitness and professional appearance. Trust us for top-notch security with impeccable presentation",
  },
  {
    image: repair,
    title: "Individual approach",
    description:
      "We provide customized security solutions tailored to your unique needs. Trust us for personalized protection and exceptional service",
  },
];
