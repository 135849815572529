// src/components/Footer.js
import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "../../assets/logo.png"; // Replace with your logo file path
import insta from "../../assets/insta.png"; // Replace with your logo file path
import yt from "../../assets/yt.png"; // Replace with your logo file path

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      className={classes.footer}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.footerContent}>
        <Box className={classes.infoContainer}>
          <Typography variant="body2" color="black" component="p">
            © {new Date().getFullYear()} ManKart. All rights reserved.
          </Typography>
        </Box>
        <Box className={classes.logoContainer}>
          <img src={Logo} alt="Company Logo" className={classes.logo} />
        </Box>
        <Box className={classes.infoContainer}>
          <Typography variant="body2" color="black" component="p">
            Contact us: mankartsecurities@gmail.com
          </Typography>
          <Typography variant="body2" color="black" component="p">
            Follow us:
            <Button color="primary" size="small" href="https://www.instagram.com/mankart_w7w?igsh=ZGs0cTBmc3Z1M3Jz">
              <img
                src={insta}
                alt="Company Logo"
                className={classes.insta}
              />
            </Button>
            <Button color="primary" size="small" href="https://youtube.com/@mankart-w7w?si=Lf9KaZ0sKGexEGus">
              <img src={yt} alt="Company Logo" className={classes.yt} />
            </Button>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: "#f0f0f0",
    borderTop: "1px solid #ddd",
    textAlign: "center",
    marginTop: "auto", // Push the footer to the bottom
  },
  footerContent: {
    display: "flex",
    flexDirection: "row", // Align items in a row
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
    maxWidth: "1200px", // Adjust the maxWidth if needed
    margin: "0 auto", // Center the content
  },
  logoContainer: {
    flex: 1,
    justifyContent: "center",
  },
  logo: {
    height: 60, // Adjust height of the logo as needed
    marginRight: "20px", // Add space to the right of the logo
  },
  infoContainer: {
    flex: 1,
    textAlign: "center",
  },
  yt: {
    width: 28,
    height: 28,
  },
  insta:{
    width: 32,
    height: 32,
  }
}));

export default Footer;
