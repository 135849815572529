import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import Lottie from "lottie-react";
import loading from "../../assets/gif/loading.json";

const LoadingModal = () => {
  return (
    <Dialog
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent
        style={{
          textAlign: "center",
          backgroundColor: "transparent",
          padding: "0",
        }}
      >
        <div style={{ width: "50%", height: "50%", margin: "auto" }}>
          <Lottie animationData={loading} loop={true} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default LoadingModal;
