import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "./index.css";
import award from "../../assets/award.svg";
import growth from "../../assets/growth.svg";
import shift from "../../assets/shift.svg";
import team from "../../assets/team.svg";
import training from "../../assets/training.svg";

const CustomCard = styled(Card)(({ theme }) => ({
  width: 290,
  border: "1px solid #B2B9BF",
  borderRadius: 20,
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  backdropFilter: "blur(17px)", // Applying the blur effect
  WebkitBackdropFilter: "blur(17px)", // For Safari support

  [theme.breakpoints.up("1200")]: {
    position: "absolute",
    top: "236px",
    right: "112px",
  },
  [theme.breakpoints.down("700")]: {
    marginLeft: -102,
  },
  [theme.breakpoints.down("500")]: {
    width: 210,
    marginLeft: -72,
  },
}));

const FacilitiesCard = () => {
  const cards = [
    { img: shift, title: "Flexible Shifts" },
    { img: growth, title: "Career Growth" },
    { img: training, title: "Professional Training" },
    { img: award, title: "Recognition and Awards" },
    { img: team, title: "Team Building Activities" },
  ];
  return (
    <CustomCard className="customCard">
      <CardContent>
        <Typography
          sx={{
            marginBottom: 2,
            fontFamily: "Khula",
            fontWeight: "bold",
            fontSize: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "24px",
            },
          }}
          variant="h6"
          color={"#fff"}
          component="div"
        >
          Guardians facilities
        </Typography>
        {cards.map((card) => (
          <div className="card">
            <img src={card.img} className="titleImg" alt={card.title} />
            <p className="facilitiesDescription">{card.title}</p>
          </div>
        ))}
      </CardContent>
    </CustomCard>
  );
};

export default FacilitiesCard;
