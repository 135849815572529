// src/HomePage.js
import React, { useRef } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import guard from "../../assets/guard.png";
import blackGuard from "../../assets/blackGuard.png";
import phoneCall from "../../assets/phoneCall.png";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import FacilitiesCard from "../../components/FacilitiesCard";
import whyChooseGuard from "../../assets/whyChooseGuard.png";
import reliableProtection from "../../assets/reliableProtection.png";
import guardsWhyChooseUs from "../../assets/guardsWhyChooseUs.png";
import { cardsData, services } from "../../helper/content";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import { isValidPhoneNumber, validateEmail } from "../../helper/regix";
import LoadingModal from "../../components/Loader";
import SuccessModal from "../../components/SuccessModal";

const HomePage = () => {
  const serviceRef = useRef();
  const chooseUsRef = useRef();
  const bookNowRef = useRef();
  const contactRef = useRef();
  const client = generateClient();
  const [loading, setLoading] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [guardInfo, setGuardInfo] = React.useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [companyInfo, setCompanyInfo] = React.useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const submitGuardForm = async (event) => {
    event.preventDefault();
    if (guardInfo.name === "") {
      alert("Name is required!");
      return;
    }
    if (guardInfo.phone === "") {
      alert("Phone Number is required!");
      return;
    }
    if (!isValidPhoneNumber(guardInfo.phone) || guardInfo.phone.length < 10) {
      alert("Phone Number is not valid!");
      return;
    }

    if (guardInfo.address === "") {
      alert("Address is required!");
      return;
    }
    if (guardInfo.email !== "" && !validateEmail(guardInfo.email)) {
      alert("Email is not valid!");
      return;
    }
    const guardInput = {
      name: guardInfo.name,
      email: guardInfo.email,
      phone: guardInfo.phone,
      address: guardInfo.address,
      isNew: true,
    };
    setLoading(true);
    await client
      .graphql({
        query: mutations.createGuardRequest,
        variables: {
          input: guardInput,
        },
      })
      .then(() => {
        setLoading(false);
        setGuardInfo({
          name: "",
          email: "",
          phone: "",
          address: "",
        });
        setSuccessModal(true);
      })
      .catch((err) => {
        setLoading(false);
        alert("Error! Try again.");
      });
    console.log("Ended");
  };
  const submitCompanyForm = async (event) => {
    event.preventDefault();
    if (companyInfo.name === "") {
      alert("Name is required!");
      return;
    }
    if (companyInfo.phone === "") {
      alert("Phone Number is required!");
      return;
    }
    if (
      !isValidPhoneNumber(companyInfo.phone) ||
      companyInfo.phone.length < 10
    ) {
      alert("Phone Number is not valid!");
      return;
    }

    if (companyInfo.address === "") {
      alert("Address is required!");
      return;
    }
    if (companyInfo.email !== "" && !validateEmail(companyInfo.email)) {
      alert("Email is not valid!");
      return;
    }
    const companyInput = {
      name: companyInfo.name,
      email: companyInfo.email,
      phone: companyInfo.phone,
      address: companyInfo.address,
      isNew: true,
    };
    setLoading(true);
    await client
      .graphql({
        query: mutations.createCompanyRequest,
        variables: {
          input: companyInput,
        },
      })
      .then(() => {
        setLoading(false);
        setCompanyInfo({
          name: "",
          email: "",
          phone: "",
          address: "",
        });
        setSuccessModal(true);
      })
      .catch(() => {
        setLoading(false);
        alert("Error! Try again.");
      });
  };
  return (
    <>
      <Header
        scrollToView={(pageId) => {
          console.log("pageId", pageId);
          if (pageId === "Services" && serviceRef.current) {
            serviceRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }
          if (pageId === "Why Choose Us" && chooseUsRef.current) {
            chooseUsRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }
          if (pageId === "Book Now" && bookNowRef.current) {
            bookNowRef.current.scrollIntoView({ behavior: "smooth" });
            return;
          }
        }}
        contactUsPress={() => {
          contactRef.current.scrollIntoView({ behavior: "smooth" });
        }}
      />
      <Box className="securityBox">
        <Box
          style={{
            display: "flex",
            flex: 1.5,
            flexDirection: "column",
          }}
        >
          <div className="textDiv">
            <p className="guardianText">Guardians of Security</p>
            <p className="joinUsText">Join Us!</p>
          </div>
          <div className="guardFormDiv">
            <Typography
              variant="h5"
              sx={{
                marginBottom: "20px",
                fontFamily: "Khula",
                fontWeight: "600",
              }}
            >
              Request a quote
            </Typography>
            <TextField
              label="Name"
              variant="standard"
              fullWidth
              value={guardInfo.name}
              onChange={(e) => {
                setGuardInfo({ ...guardInfo, name: e.target.value });
              }}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": { border: "none" },
              }}
            />
            <TextField
              label="Email"
              variant="standard"
              fullWidth
              value={guardInfo.email}
              onChange={(e) => {
                setGuardInfo({ ...guardInfo, email: e.target.value });
              }}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": { border: "none" },
              }}
            />
            <TextField
              label="Phone"
              variant="standard"
              fullWidth
              value={guardInfo.phone}
              onChange={(e) => {
                setGuardInfo({ ...guardInfo, phone: e.target.value });
              }}
              sx={{
                marginBottom: "15px",
                "& .MuiOutlinedInput-root": { border: "none" },
              }}
            />
            <TextField
              label="Address"
              variant="standard"
              fullWidth
              value={guardInfo.address}
              onChange={(e) => {
                setGuardInfo({ ...guardInfo, address: e.target.value });
              }}
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": { border: "none" },
              }}
            />
            <Button
              onClick={(e) => submitGuardForm(e)}
              style={{ borderRadius: 24, backgroundColor: "#001D3D" }}
              variant="contained"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            flex: 1,
            backgroundColor: "#001D3D",
          }}
        >
          <img src={guard} className="guardImg" alt="" />
          {/* <p className="blueSecurityText">SECURITY</p> */}
          <div className="facilityCard">
            <FacilitiesCard />
          </div>
        </Box>
      </Box>
      <Container ref={serviceRef} sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            marginTop: {
              xs: 6,
              sm: 8,
              md: 10,
              lg: 12,
            },
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "Khula",
          }}
          color="#001d3d"
          variant="h4"
          gutterBottom
        >
          Security Services
        </Typography>
        <Grid container spacing={0} justifyContent="center">
          {services.map((service, index) => (
            <div id={index} className="image-card">
              <img
                id={index}
                className="image-card__image"
                src={service.image}
                alt="Service"
              />
              <div className="image-card__content">
                <h3 className="image-card__title">{service.title}</h3>
                <p className="image-card__description">{service.description}</p>
              </div>
            </div>
          ))}
        </Grid>
      </Container>
      <Box
        className="whyChooseMainBox"
        display="flex"
        sx={{
          marginBlock: {
            xs: 10,
            sm: 14,
            md: 16,
            lg: 20,
          },
          justifyContent: "center",
        }}
      >
        <div className="image-box">
          <div className="row">
            <img src={reliableProtection} alt="" className="image" />
            <img src={whyChooseGuard} alt="" className="image" />
          </div>
          <div className="row">
            <img src={guardsWhyChooseUs} alt="" className="image-large" />
          </div>
        </div>
        <div ref={chooseUsRef} className="whyUsDiv">
          <Typography
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              fontFamily: "Khula",
              marginLeft: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 3,
              },
            }}
            color="#001d3d"
            variant="h4"
            gutterBottom
          >
            Why Choose Us ?
          </Typography>
          <div className="card-container">
            {cardsData.map((card, index) => (
              <div className="cardChooseUs">
                <img src={card.image} alt={card.title} className="card-image" />
                <h2 className="card-title">{card.title}</h2>
                <p className="card-description">{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Box>
      <div>
        <Box
          ref={bookNowRef}
          display="flex"
          className="wantGuardGrid"
          container
          sx={{ width: "100%", marginTop: 2 }}
        >
          <div style={{ flex: 1 }}>
            <div>
              <p
                className="bookGuard"
                style={{
                  left: 60,
                }}
              >
                Book a Guard
                <br /> Today!
              </p>
              <p
                ref={contactRef}
                style={{
                  position: "absolute",
                  color: "#fff",
                  fontSize: "20px",
                  left: 60,
                  marginTop: "460px",
                  fontFamily: "Khula",
                }}
              >
                <img
                  style={{ width: "16px", height: "16px" }}
                  src={phoneCall}
                  alt=""
                />{" "}
                Call us at (+91 1734-358578)
              </p>
              <img
                src={blackGuard}
                style={{ width: "100%", height: "531px" }}
                alt=""
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <Box
              sx={{
                width: "100%",
                height: 531,
                backgroundColor: "#001D3D",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  color: "white",
                  marginBottom: 2,
                  marginTop: 8,
                  width: "80%",
                  fontWeight: "600",
                  fontFamily: "Khula",
                }}
              >
                Wanted Security Guard
              </Typography>
              <TextField
                value={companyInfo.name}
                onChange={(e) => {
                  setCompanyInfo({ ...companyInfo, name: e.target.value });
                }}
                variant="outlined"
                placeholder="Name"
                sx={{
                  borderRadius: 2,
                  width: "80%",
                  marginBottom: 2,
                  backgroundColor: "#042647",
                  "& .MuiInputBase-input": { color: "white" },
                  "& .MuiInputBase-input::placeholder": { color: "white" },
                }}
              />
              <TextField
                value={companyInfo.email}
                onChange={(e) => {
                  setCompanyInfo({ ...companyInfo, email: e.target.value });
                }}
                variant="outlined"
                placeholder="Email"
                sx={{
                  borderRadius: 2,
                  width: "80%",
                  marginBottom: 2,
                  backgroundColor: "#042647",
                  "& .MuiInputBase-input": { color: "white" },
                  "& .MuiInputBase-input::placeholder": { color: "white" },
                }}
              />
              <TextField
                value={companyInfo.phone}
                onChange={(e) => {
                  setCompanyInfo({ ...companyInfo, phone: e.target.value });
                }}
                variant="outlined"
                placeholder="Phone"
                sx={{
                  borderRadius: 2,
                  width: "80%",
                  marginBottom: 2,
                  backgroundColor: "#042647",
                  "& .MuiInputBase-input": { color: "white" },
                  "& .MuiInputBase-input::placeholder": { color: "white" },
                }}
              />
              <TextField
                value={companyInfo.address}
                onChange={(e) => {
                  setCompanyInfo({ ...companyInfo, address: e.target.value });
                }}
                variant="outlined"
                placeholder="Address"
                sx={{
                  borderRadius: 2,
                  width: "80%",
                  marginBottom: 2,
                  backgroundColor: "#042647",
                  "& .MuiInputBase-input": { color: "white" },
                  "& .MuiInputBase-input::placeholder": { color: "white" },
                }}
              />
              <Button
                onClick={(e) => submitCompanyForm(e)}
                variant="contained"
                type="submit"
                style={{
                  backgroundColor: "#FFC300",
                  color: "black",
                  borderRadius: 24,
                  width: "80%",
                  marginBottom: 8,
                  marginTop: 2,
                }}
              >
                Submit
              </Button>
            </Box>
          </div>
        </Box>
      </div>
      <Footer />
      <SuccessModal
        open={successModal}
        handleClose={() => setSuccessModal(false)}
      />
      {loading && <LoadingModal />}
    </>
  );
};

export default HomePage;
