/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGuardRequest = /* GraphQL */ `
  mutation CreateGuardRequest(
    $input: CreateGuardRequestInput!
    $condition: ModelGuardRequestConditionInput
  ) {
    createGuardRequest(input: $input, condition: $condition) {
      id
      email
      name
      phone
      address
      isNew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGuardRequest = /* GraphQL */ `
  mutation UpdateGuardRequest(
    $input: UpdateGuardRequestInput!
    $condition: ModelGuardRequestConditionInput
  ) {
    updateGuardRequest(input: $input, condition: $condition) {
      id
      email
      name
      phone
      address
      isNew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGuardRequest = /* GraphQL */ `
  mutation DeleteGuardRequest(
    $input: DeleteGuardRequestInput!
    $condition: ModelGuardRequestConditionInput
  ) {
    deleteGuardRequest(input: $input, condition: $condition) {
      id
      email
      name
      phone
      address
      isNew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyRequest = /* GraphQL */ `
  mutation CreateCompanyRequest(
    $input: CreateCompanyRequestInput!
    $condition: ModelCompanyRequestConditionInput
  ) {
    createCompanyRequest(input: $input, condition: $condition) {
      id
      email
      phone
      address
      isNew
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyRequest = /* GraphQL */ `
  mutation UpdateCompanyRequest(
    $input: UpdateCompanyRequestInput!
    $condition: ModelCompanyRequestConditionInput
  ) {
    updateCompanyRequest(input: $input, condition: $condition) {
      id
      email
      phone
      address
      isNew
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyRequest = /* GraphQL */ `
  mutation DeleteCompanyRequest(
    $input: DeleteCompanyRequestInput!
    $condition: ModelCompanyRequestConditionInput
  ) {
    deleteCompanyRequest(input: $input, condition: $condition) {
      id
      email
      phone
      address
      isNew
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
