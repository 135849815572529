import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from "../../assets/logo.png";
import "../../screens/Home/index.css";

const pages = ["Services", "Why Choose Us", "Book Now"];

const ResponsiveAppBar = (props) => {
  return (
    <div className="appBarContainer">
      <Box
        display="flex"
        sx={{
          flex: 1.5,
        }}
      >
        <img src={logo} alt="logo" loading="lazy" className="logo" />
        {pages.map((page) => (
          <Typography
            key={page}
            variant="h7"
            onClick={() => props?.scrollToView(page)}
            sx={{
              marginRight: {
                xs: "10px", // margin-right for extra-small screens
                sm: "20px", // margin-right for small screens
                md: "40px", // margin-right for medium screens
                lg: "74px", // margin-right for large screens
              },
              cursor: "pointer",
              color: "black",
              marginTop: "28px",
              fontSize: {
                xs: "0.4rem", // font-size for extra-small screens
                sm: "0.6rem", // font-size for small screens
                md: "0.8rem", // font-size for medium screens
                lg: "1rem", // font-size for large screens
              },
              display: {
                xs: "none", // font-size for extra-small screens
                sm: "none", // font-size for small screens
                md: "flex", // font-size for medium screens
                lg: "flex",
              },
            }}
          >
            {page}
          </Typography>
        ))}
      </Box>
      <Box
        display="flex"
        sx={{
          display: {
            xs: "none", // font-size for extra-small screens
            sm: "none",
            md: "none",
            lg: "flex",
          },
          backgroundColor: "#001D3D",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        <div className="contactButtonContainer">
          <Typography
            key="contact"
            variant="h7"
            onClick={props.contactUsPress}
            sx={{
              cursor: "pointer",
              color: "black",
              fontSize: {
                xs: "0.4rem", // font-size for extra-small screens
                sm: "0.6rem", // font-size for small screens
                md: "0.8rem", // font-size for medium screens
                lg: "1rem", // font-size for large screens
              },
            }}
          >
            Contact Us
          </Typography>
        </div>
      </Box>
    </div>
  );
};

export default ResponsiveAppBar;
